.question-hint {
    font-size: 1em !important;
    color: #52beb2;
    opacity: .8;
    padding: .5em;
    border: 1px solid #52beb2;
    border-radius: 50px;
    width: 100%;
    max-width: 500px;
    display: block;
    margin: 0 auto;
}
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 1px 0;
  position: relative;
}
.inputGroup label {
  border-radius: 50px;
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-transition: color 200ms ease-in;
  transition: color 200ms ease-in;
  overflow: hidden;
}
.inputGroup label:hover {
    background: #52beb2;
    color: #fff;
    transition: .4s;
}
.inputGroup label:before {
  border-radius: 50px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #ec6563;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  /* background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E "); */
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #fff;
  border-color: #ec6563;
  z-index: 10;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  -webkit-box-ordinal-group: 2;
          order: 1;
  z-index: 10;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

.quote.stripe.segment .grid .column {
  padding: 0 1em;
}

.inputGroup .order-number {
  color: #ec6563;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 32px;
  height: 32px;
  z-index: 9999;
  position: absolute;
  right: 18px;
  top: 61%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.ui.grid {
  margin-bottom: 2em;
}
.ui.progress{
    margin: 0 auto!important;
}