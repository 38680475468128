body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  color: #767676;
}
.hidden.menu {
  display: none;
}
.ui.three.cards>.card {
    margin: 1em auto;
}

.column.explorer::after {
  background-image: url("https://api.individualogist.com/img/archetype/Explorer.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.1;
  min-height: 308px;
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.column.rebel::after {
  background-image: url("https://api.individualogist.com/img/archetype/OutlawC.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 408px;
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.masthead.segment {
  min-height: 400px;
  padding: 1em 0em;
}
.masthead .logo.item img {
  margin-right: 1em;
}
.masthead .ui.menu .ui.button {
  margin-left: 0.5em;
}
.masthead h1.ui.header {
  margin-top: 3em;
  margin-bottom: 0em;
  font-size: 4em;
  font-weight: normal;
}
.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.ui.inverted.segment {
  margin: 0px;
  border-radius: 0px;
}
.ui.inverted.secondary.menu {
  margin: 0 30px;
  min-height: 95px;
}
.ui.vertical.stripe {
/*  padding: 8em 0em 0em 0em;*/
    padding: 0;
}
.ui.vertical.stripe h3 {
  font-size: 2em;
}
.ui.vertical.stripe .button + h3,
.ui.vertical.stripe p + h3 {
  margin-top: 3em;
}
.ui.vertical.stripe .floated.image {
  clear: both;
}
.ui.vertical.stripe p {
  font-size: 1.33em;
}
.ui.vertical.stripe .horizontal.divider {
  margin-top: 1em;
  color: #ec6563;
}
.ui.vertical.segment {
  border: 0px;
}
.ui.inverted.segment, .ui.primary.inverted.segment {
  background:  #ec6563;
}
.ui.inverted.segment.linear-background {
  background:  linear-gradient(to bottom, #ec6563 60%, #ffffff 0%);
}
.download {
    font-size:1.2em !important;
}
.download:hover {
    background: #ec6463;
    box-shadow: 0 5px 15px rgba(0,0,0,.15) !important;
    transition: .4s;
    -webkit-transition: .4s;
}
.text-orange {
  color: #ec6563;
  margin-top:3em!important;
}
.mb-1{
    margin-bottom:1em !important;
}
.text-white,.text-white.ui.header {
    color:#fff !important;
}
.sales-letter {
    max-width:900px!important;
    width:100%;
    margin:0 auto;
    padding: 0 1.5em;
}
.sales-letter p {
    line-height:2em!important;
}
.headline {
    border-radius:1em;
    background: #ec6563;
    box-shadow: 0 10px 20px rgba(0,0,0,.15), 0 20px 40px rgba(0,0,0,0.15);;
}
.headline:before{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    height: 3px;
    z-index: -1;
    width: 100%;
    content: '';
    background: #ed6463;
}
.ui.icon.input>i.icon {
    color:#ed6463 !important;
}
.background-orange {
  background: #ec6563;
  color: #ffffff;
  border-radius: 15px;
  padding:1em !important;
}
.background-toska {
  background: #52beb2;
  padding: 2em;
  margin-top: 120px;
  border-radius: 60px;
}
.caregiver-card {
  background: #923434;
  color: #ffffff;
}
.creator-card {
  background: #567224;
  color: #ffffff;
}
.explorer-card {
  background: #2c3135;
  color: #ffffff;
}
.warrior-card {
  background: #1c3f4a;
  color: #ffffff;
}
.idealist-card {
  background: #a88710;
  color: #ffffff;
}
.jester-card {
  background: #2b76aa;
  color: #ffffff;
}
.lover-card {
  background: #d43e3f;
  color: #ffffff;
}
.magician-card {
  background: #283155;
  color: #ffffff;
}
.member-card {
  background: #796e7e;
  color: #ffffff;
}
.rebel-card {
  background: #5e5131;
  color: #ffffff;
}
.ruler-card {
  background: #aa8f00;
  color: #ffffff;
}
.sage-card {
  background: #857a56;
  color: #ffffff;
}
.content.inside.background {
  min-height:308px;
}
.content.inside.background img {
  max-width: 250px;
  padding: 2em;
  margin-top: 100px;
}
.quote.stripe.segment .grid .column.background-orange.form {
  padding: 5em;
}

.ui.footer {
  color: #ec6563;
  border-top: 1px solid;
  padding-top: 1em;
}
.ui.footer p {
  font-size: 0.8em !important;
}
.ui.footer img {
  max-width: 50px;
  margin: 1em;
}
.ui.footer .copyright {
  font-size: 1em;
  margin: 1em;
}
.footer-desc{
    margin:1em auto;
}
.b-special {
  background: #ffffff !important;
  color: #ec6563 !important;
}
.b-special:after {
    font-family: Icons;
    content: "\f152";
    padding-left:10px;
}
.b-special:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,.15) !important;
    background: #52beb2 !important;
    color:#fff !important;
    transition:.4s;
    -webkit-transition:.4s;
}
.ui.button {
  background: #52beb2;
  color: #ffffff;
  border-radius: 50px;
}
.ui.button.white:hover,.ui.button:hover{
    background: #52beb2 !important;
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    color: #fff;
    transition:.4s;
    -webkit-transition:.4s;
}
.ui.button.white {
    background: #fff;
    color: #ed6463;
}
.ui.secondary.menu .item{
  border-radius:50px;
}
.ui.button.login {
  background: #ffffff;
  color: #ec6563;
}
.ui.button.login:hover{
    background: #52beb2;
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    color: #fff;
    transition:.4s;
    -webkit-transition:.4s;    
}
.ui.button.social {
  background: #ec6563;
  margin: 5px;
  padding: 10px 0px 10px 12px;
}
.ui.header {
  color: #52beb2;
  padding: 15px 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.header {
    color:#52beb2!important
}
.ui.archetype {
  color: #fff;
  text-align: center;
  margin: 0 0 2em 0;
}
.archetype-text {
  text-align: center;
  margin: 1em 0 0 0;
}
.right.item {
  font-size: 20px;
}
.quote.stripe.segment {
  padding: 0em;
}
.quote.stripe.segment .grid .column {
  padding: 5em 3em;
}

.footer.segment {
  padding: 5em 0em;
}
.secondary.inverted.menu .toc.item {
  display: none;
}

.sidebar-menu {
  display: none
}

.logo-img {
  max-width: 250px;
}
.text-divider{
    text-align: center;
}
.line-divider {
    width: 80%;
    height: 1px;
    background: #e8e8e8;
    position: relative;
    display: block;
    padding: 0;
    margin: 1.5em auto;
}
.ui.form .field>label {
  color: #ffffff;
  float: left;
}
.ui.form .field {
    font-size: 1.1em;
}

/* new carousel */
.scene {
  /* border: 1px solid #CCC; */
  position: relative;
  width: 212px;
  height: 290px;
  margin: 80px auto;
  perspective: 1000px;
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-288px);
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel__cell {
  position: absolute;
  width: 190px;
  height: 120px;
  left: 10px;
  top: 10px;
  /* border: 2px solid black; */
  line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-align: center;
  transition: transform 1s, opacity 1s;
}

.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.8);
}
.carousel-img {
  width: 100%;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent) , to(rgba(250, 250, 250, 0.3)));
}
.next, .prev {
  color: #ffffff;
  font-size: 80px;
  position: absolute;
  top: 178px;
  cursor: pointer;
  transition: box-shadow 0.1s, top 0.1s;
}
.next:hover, .prev:hover { color: #b9b5b5; }
.next:active, .prev:active {
  top: 178px;
  /* box-shadow: 0 1px 0 #999; */
}
.next { right: 1em; }
.prev { left: 1em; }

.ui.list>a.item {
    color:#ec6563
}
.ui.list>a.item:hover{
    transition:.4s;
    -webkit-transition:.4s;
    color:#52beb2
}
.background-toska h3, .background-toska p {
    color:#fff
}
.qn-opt {
    font-size:1em !important;
    width:90%
}
.ui.basic.red.label {
    background: #db2828 !important;
    color: #fff !important;
    border-color: #db2828!important;
}
.ui[class*="pointing below"].label {
    margin-top: 0;
    margin-bottom: 1em;
    position: absolute;
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    top: -27px;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
}
@media only screen and (max-width: 700px) {
    .inputGroup label{
        padding:12px !important;
        border-radius:5px!important;
    }
    .qn-opt{
        margin-bottom:1em!important;
        width:100%!important;
        padding-bottom:1em!important;
        text-align:center!important;
    }
    .inputGroup .order-number {
        right: 0!important;
        left: 0!important;
        width:25px!important;
        height:25px !important;
        top:auto !important;
        bottom: 0!important;
        margin: auto!important;
    }
    .inputGroup label:after {
        width:25px !important;
        height:25px !important;
        right: 0!important;
        top: auto !important;
        left: 0!important;
        bottom: 0!important;
        margin: auto!important;
    }
  h2.ui.header{
    text-align:center;
  }
  .ui.vertical.stripe .horizontal.divider {
    margin-top: 3em;
  }
  .ui.inverted.secondary.menu .item {
    display: none;
  }
  .ui.inverted.secondary.menu .toc.item,
  .ui.inverted.secondary.menu .toc.item {
    display: block;
    width: 80%;
    margin: auto;
  }
  .ui.inverted.secondary.menu .toc.item img {
    width: auto;
    max-width: 80%;
    margin: auto;
    display: block;  
  }
  .ui.vertical.stripe{
    padding-bottom: 10px;
  }
  .ui.vertical.stripe p {
    font-size:1.1em;
  }
  .left-line {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }    
  .ui.fixed.menu {
    display: none !important;
  }
  /* .custom .toc.item {
    position: absolute;
    display: block;
    margin: 5px;
  } */
  .mobile.right.item {
    display: block;
  }
  .masthead.segment {
    min-height: 350px;
  }
  .masthead h1.ui.header {
    font-size: 2em;
    margin-top: 1.5em;
  }
  .masthead h2 {
    margin-top: 0.5em;
    font-size: 1.5em;
  }
  .ui.vertical.stripe h3 {
    font-size: 1.5em;
  }
  .content.inside.background img {
    margin: 170px 0;
  }
  .contain {
    margin: 140px 0;
  }
  .sidebar-menu {
    display: block
  }
  .carousel {
    width: 100%;
  }
  .next, .prev {
    top: 400px;
    color: #52beb2;
  }
  .next:active, .prev:active {
    top: 400px;
    /* box-shadow: 0 1px 0 #999; */
  }
    .ui.horizontal.new-divider.text-question {
        width: 100% !important;
        margin: 0 auto !important;
    }
    .ui.horizontal.new-divider:before{
        display:none!important;
    }
    .ui.horizontal.new-divider:after{
        display:none!important;
    }
}

.test-questions.comp{
  margin:0 auto;
  width:100%;
  max-width:900px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  padding:15px
}
@media (min-width:400px){
  .test-questions.comp{
      padding:15px 30px
  }
}
@media (min-width:992px){
  .test-questions.comp{
      padding:15px
  }
}
.test-questions.comp .question{
  padding:0 0 20px;
  width:100%;
  max-width:400px;
}
@media (min-width:768px){
  .test-questions.comp .question{
      max-width:none
  }
}
@media (min-width:992px){
  .test-questions.comp .question{
      padding:20px 0 20px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question{
      padding:60px 0 45px
  }
  .logo-img {
    width: 100%
  }
}
.test-questions.comp .question .statement{
  color:#576071;
  font-weight:600;
  text-align:center;
  line-height:1.5
}
@media (min-width:768px){
  .test-questions.comp .question .statement{
      font-size:1.2em
  }
}
@media (min-width:992px){
  .test-questions.comp .question .statement{
      font-size:1.3em
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .statement{
      font-size:1.4em
  }
}
.test-questions.comp .question .decision{
  display:-webkit-box;
  display:-ms-flexbox;
  /* display:flex; */
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  /* justify-content:center; */
  margin:30px 0 15px
}
@media (min-width:768px){
  .test-questions.comp .question .decision{
      margin:30px 0 20px
  }
}
@media (min-width:768px) and (min-height:900px){
  .test-questions.comp .question .decision{
      margin-top:40px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision{
      margin:60px 0 30px
  }
}
.test-questions.comp .question .decision:not(.mobile) .caption{
  display:none
}
@media (min-width:768px){
  .test-questions.comp .question .decision:not(.mobile) .caption{
      display:block
  }
}
.test-questions.comp .question .decision.mobile{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between
}
@media (min-width:768px){
  .test-questions.comp .question .decision.mobile{
      display:none
  }
}
.test-questions.comp .question .decision .caption{
  font-weight:600;
  font-size:.8em;
  text-transform:uppercase
}
.test-questions.comp .question .decision .caption.agree{
  color:#56ac8a
}
.test-questions.comp .question .decision .caption.disagree{
  color:#5f394d
}
@media (min-width:768px){
  .test-questions.comp .question .decision .caption{
      font-size:1em;
      text-transform:none
  }
}
@media (min-width:768px) and (min-height:900px){
  .test-questions.comp .question .decision .caption{
      font-size:1.1em
  }
}
@media (min-width:992px){
  .test-questions.comp .question .decision .caption{
      font-size:1.1em
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .caption{
      font-size:1.3em
  }
}
.test-questions.comp .question .decision .options{
  -webkit-box-flex:0;
  -ms-flex:0 0 100%;
  flex:0 0 100%;
  margin:0 15px;
  display:-webkit-box;
  display:-ms-flexbox;
  /* display:flex; */
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between
}
@media (min-width:768px){
  .test-questions.comp .question .decision .options{
      -webkit-box-flex:0;
      /* -ms-flex:0 0 400px; */
      /* flex:0 0 400px; */
      margin:0 30px
  }
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options{
      -webkit-box-flex:0;
      -ms-flex:0 0 500px;
      /* flex:0 0 500px */
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options{
      -webkit-box-flex:0;
      -ms-flex:0 0 600px;
      flex:0 0 800px;
      margin:0 60px
  }
}
.test-questions.comp .question .decision .options .option{
  border-radius:50%;
  cursor:pointer;
  -webkit-transition:all .1s ease-in-out;
  transition:all .1s ease-in-out;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.test-questions.comp .question .decision .options .option.agree{
  border:2px solid #56ac8a
}
.test-questions.comp .question .decision .options .option.agree.active,.test-questions.comp .question .decision .options .option.agree:hover{
  background-color:#56ac8a
}
.test-questions.comp .question .decision .options .option.neutral{
  border:2px solid #9b9faa;
  -webkit-box-flex:0;
  -ms-flex:0 0 25px;
  flex:0 0 25px;
  max-width:25px;
  height:25px
}
.test-questions.comp .question .decision .options .option.neutral.active,.test-questions.comp .question .decision .options .option.neutral:hover{
  background-color:#9b9faa
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options .option.neutral{
      -webkit-box-flex:0;
      -ms-flex:0 0 30px;
      flex:0 0 30px;
      max-width:30px;
      height:30px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.neutral{
      -webkit-box-flex:0;
      -ms-flex:0 0 35px;
      flex:0 0 35px;
      max-width:35px;
      height:35px
  }
}
.test-questions.comp .question .decision .options .option.neutral span{
  font-size:.7em
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.neutral span{
      font-size:.9em
  }
}
.test-questions.comp .question .decision .options .option.disagree{
  border:2px solid #5f394d
}
.test-questions.comp .question .decision .options .option.disagree.active,.test-questions.comp .question .decision .options .option.disagree:hover{
  background-color:#5f394d
}
.test-questions.comp .question .decision .options .option.max{
  -webkit-box-flex:0;
  -ms-flex:0 0 45px;
  flex:0 0 45px;
  max-width:45px;
  height:45px
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options .option.max{
      -webkit-box-flex:0;
      -ms-flex:0 0 55px;
      flex:0 0 55px;
      max-width:55px;
      height:55px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.max{
      -webkit-box-flex:0;
      -ms-flex:0 0 70px;
      flex:0 0 70px;
      max-width:70px;
      height:70px
  }
}
.test-questions.comp .question .decision .options .option.max span{
  font-size:1.1em;
  margin-top:3px
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.max span{
      font-size:1.5em
  }
}
.test-questions.comp .question .decision .options .option.med{
  -webkit-box-flex:0;
  -ms-flex:0 0 35px;
  flex:0 0 35px;
  max-width:35px;
  height:35px
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options .option.med{
      -webkit-box-flex:0;
      -ms-flex:0 0 45px;
      flex:0 0 45px;
      max-width:45px;
      height:45px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.med{
      -webkit-box-flex:0;
      -ms-flex:0 0 55px;
      flex:0 0 55px;
      max-width:55px;
      height:55px
  }
}
.test-questions.comp .question .decision .options .option.med span{
  font-size:1em;
  margin-top:2px
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.med span{
      font-size:1.2em
  }
}
.test-questions.comp .question .decision .options .option.min{
  -webkit-box-flex:0;
  -ms-flex:0 0 28px;
  flex:0 0 28px;
  max-width:28px;
  height:28px
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options .option.min{
      -webkit-box-flex:0;
      -ms-flex:0 0 35px;
      flex:0 0 35px;
      max-width:35px;
      height:35px
  }
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.min{
      -webkit-box-flex:0;
      -ms-flex:0 0 45px;
      flex:0 0 45px;
      max-width:45px;
      height:45px
  }
}
.test-questions.comp .question .decision .options .option.min span{
  font-size:.8em;
  margin-top:1px
}
@media (min-width:992px) and (min-height:900px){
  .test-questions.comp .question .decision .options .option.min span{
      font-size:1em
  }
}
.test-questions.comp .question .decision .options .option.active span{
  color:#fff
}
@media (min-width:992px){
  .test-questions.comp .question .decision .options .option.agree,.test-questions.comp .question .decision .options .option.disagree,.test-questions.comp .question .decision .options .option.neutral{
      border-width:3px
  }
}
.test-questions.comp .question .decision .options .option span{
  color:transparent
}
.social-cta.comp{
  padding:75px 15px 30px;
  text-align:center;
  font-size:1.2em;
  color:#9b9faa;
  text-transform:uppercase
}
@media (min-width:400px){
  .social-cta.comp{
      padding:75px 30px 30px
  }
}
@media (min-width:992px){
  .social-cta.comp{
      padding:75px 15px 30px
  }
}
.social-cta.comp>.title{
  font-size:.8em;
  line-height:1.5
}
@media (min-width:992px){
  .social-cta.comp>.title{
      font-size:1em
  }
}
.social-cta.comp .wrapper{
  margin-top:20px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column
}
@media (min-width:992px){
  .social-cta.comp .wrapper{
      margin-top:40px
  }
}
@media (min-width:1024px){
  .social-cta.comp .wrapper{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row
  }
}
.social-cta.comp .social-count{
  font-weight:600
}
.social-cta.comp .social-count .number{
  font-size:1.5em;
  line-height:1;
  margin-bottom:3px
}
@media (min-width:992px){
  .social-cta.comp .social-count .number{
      font-size:2em;
      margin-bottom:5px
  }
}
.social-cta.comp .social-count .title{
  font-size:.6em
}
@media (min-width:992px){
  .social-cta.comp .social-count .title{
      font-size:.7em
  }
}
.social-cta.comp .social-icons{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:20px
}
@media (min-width:768px){
  .social-cta.comp .social-icons{
      min-width:180px
  }
}
@media (min-width:1024px){
  .social-cta.comp .social-icons{
      margin:0 0 0 20px
  }
}
.social-cta.comp .social-icons .icon{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-flex:0;
  -ms-flex:0 0 60px;
  flex:0 0 60px;
  height:60px;
  width:60px;
  border-radius:50%;
  color:#9b9faa;
  border:1px solid #dcdcdc;
  font-size:1.1em;
  margin:5px;
  -webkit-transition:all .1s ease-in-out;
  transition:all .1s ease-in-out;
  text-decoration:none;
  fill:#9b9faa
}
.social-cta.comp .social-icons .icon,.social-cta.comp .social-icons .icon.messenger,.social-cta.comp .social-icons .icon.whatsapp{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
@media (min-width:1024px){
  .social-cta.comp .social-icons .icon.messenger,.social-cta.comp .social-icons .icon.whatsapp{
      display:none
  }
}
.social-cta.comp .social-icons .icon:active,.social-cta.comp .social-icons .icon:focus,.social-cta.comp .social-icons .icon:hover{
  text-decoration:none;
  cursor:pointer
}
.social-cta.comp .social-icons .icon:active.twitter,.social-cta.comp .social-icons .icon:focus.twitter,.social-cta.comp .social-icons .icon:hover.twitter{
  color:#2a9fee;
  border-color:#2a9fee
}
.social-cta.comp .social-icons .icon:active.facebook,.social-cta.comp .social-icons .icon:focus.facebook,.social-cta.comp .social-icons .icon:hover.facebook{
  color:#3f5b96;
  border-color:#3f5b96
}
.social-cta.comp .social-icons .icon:active.messenger,.social-cta.comp .social-icons .icon:focus.messenger,.social-cta.comp .social-icons .icon:hover.messenger{
  color:#1783f9;
  border-color:#1783f9
}
.social-cta.comp .social-icons .icon:active.email,.social-cta.comp .social-icons .icon:focus.email,.social-cta.comp .social-icons .icon:hover.email{
  color:#3f8f6f;
  border-color:#3f8f6f
}
.social-cta.comp .social-icons .icon:active.instagram,.social-cta.comp .social-icons .icon:focus.instagram,.social-cta.comp .social-icons .icon:hover.instagram{
  color:#cd446b;
  border-color:#cd446b
}
.social-cta.comp .social-icons .icon:active.pinterest,.social-cta.comp .social-icons .icon:focus.pinterest,.social-cta.comp .social-icons .icon:hover.pinterest{
  color:#e3102e;
  border-color:#e3102e
}
.social-cta.comp .social-icons .icon:active.vkontakte,.social-cta.comp .social-icons .icon:focus.vkontakte,.social-cta.comp .social-icons .icon:hover.vkontakte{
  color:#4c75a3;
  border-color:#4c75a3
}
.social-cta.comp .social-icons .icon:active.whatsapp,.social-cta.comp .social-icons .icon:focus.whatsapp,.social-cta.comp .social-icons .icon:hover.whatsapp{
  color:#22e57e;
  border-color:#22e57e
}
@media (min-width:992px){
  .social-cta.comp .social-icons .icon{
      -webkit-box-flex:0;
      -ms-flex:0 0 70px;
      flex:0 0 70px;
      height:70px;
      width:70px;
      margin:10px
  }
}
@media (min-width:1024px){
  .social-cta.comp .social-icons .icon{
      margin:0 10px
  }
}

/* new style added */
/* The containerq */
.containerq {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin: 10px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background: antiquewhite; */
  min-height: 40px;
  padding: 11px;
}

/* Hide the browser's default radio button */
.containerq input {
position: absolute;
opacity: 0;
cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
position: absolute;
top: 0;
left: 0;
height: 40px;
width: 40px;
background-color: #eee;
border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerq:hover input ~ .checkmark {
background-color: #ccc;
}
.containerq:hover {
  background: #eee;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 20px;
}
.containerq:active {
  background: #dddddd
}

/* When the radio button is checked, add a blue background */
.containerq input:checked ~ .checkmark {
background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerq input:checked ~ .checkmark:after {
display: block;
}

/* Style the indicator (dot/circle) */
.containerq .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /* background: white; */
}

html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 46px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

ul.horizontal-list {
  list-style: none;
	columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
ul.horizontal-list li:before {
  content: '\f335';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: #cccc;
}
.ordered {
  color: white;
  font-weight: 900;
  margin: -20px 0 0 3px;
  position: absolute;
  z-index: 1;
}
.green-icon {
  color: #22e57e
}
.logout-text {
  cursor: pointer;
  float: right;
}

h2.caregiver .content {
  color: #913233;
}
h2.creator .content {
  color: #5e792f;
}
h2.explorer .content {
  color: #2d3036
}
h2.warrior .content {
  color: #284b53
}
h2.idealist .content {
  color: #a07f00
}
h2.jester .content {
  color: #1e6da4;
}
h2.lover .content {
  color: #d53f3e
}
h2.magician .content {
  color: #1b254c
}
h2.member .content {
  color: #726675
}
h2.rebel .content {
  color: #6c6245
}
h2.ruler .content {
  color: #b09910
}
h2.sage .content {
  color: #887f5a
}
.ui.progress, .ui.progress-custom .bar {
  border: none;
  border-radius: 0
}
.ui.header .content {
  text-transform: capitalize
}
.ui.button.custom-background-orange {
  background: #ec6562;
  text-transform:uppercase;
  font-size:2em!important;
  border-radius:50px!important;
  display:block!important;
  position:relative!important;
  max-width:500px !important;    
  margin:1em auto;
}
.ui.button.custom-background-orange:after {
    font-family: Icons;
    content: "\f152";
    padding-left: 10px;
}
.ui.button.custom-background-orange:hover {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #52beb2;
  color:#fff;
}
.ui.centered.column.row.remove-padding {
  padding: 0
}
h2.ui.header.custom {
  color: #000000
}
.overflow-custom {
  overflow: hidden;
}
.personalised-title {
  text-transform: uppercase;
  color: #000000;
  border-bottom: 2px solid #bbb
}

.ui.vertical.stripe .button + h3, .ui.vertical.stripe p + h3 {
  margin-top: 0;
}
.left-line {
  border-left: 1px solid #e1e2e2;
  margin-left: 2.2em;
  padding-left: 2.7em;
  margin-bottom: 0;
  margin-top: 0;
}
.ui.header {
  margin-bottom: 0;
}
h3.ui.header.quote {
  color: #767676;
}
h3.ui.header {
  color: #ec6562;
}
h3.ui.header span {
  color: #ec6562;
}
h3.ui.header.black {
  color: black;
  text-align:center;
}
.quote.stripe.segment .grid .column {
  padding: 2em 1em;
}
.section-title {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 2em;
  background: #52beb2;
  margin: 1em auto;
  padding: .5em 1em;
}
p.personalised {
  margin-top: 25px;
}
span.bold {
  font-weight: bold;
  font-size: inherit;
}
.ui.dividing.header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  padding: 30px 0;
  margin: 0 auto;
  border: 0px;
}

span.username {
  color: #ec6563;
}

.marginLeft25 {
  margin-left: 25px;
}

.ui.header span.headerTitle {
  background-color: #ec6563;
  color: white;
  padding: 20px;
  border-radius: 50px;
  text-transform: uppercase;
}

.ui.container.custom-margin {
  margin-bottom: 1em;
}

p.text-silver {
  color: #676767
}

.ui.header.quote {
  margin-top: 50px;
}
.quote {
  quotes: "“" "”";
}
.ui.header.quote:before {
    content: open-quote;
    color: #1ada8c;
    font-size: 4em;
    font-family: 'Georgia';
}
.ui.header.quote:after {
    content: close-quote;
    color: #ec6563;
    font-size: 4em;
    font-family: 'Georgia';
    /* bottom: 5.7em; */
    position: absolute;

}
.ui.text.center.aligned.container.custom-margin {
  margin-bottom: 50px;
}

.border-right-bottom {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #dddd;
}
.border-right-bottom .ui.image {
  max-width: 100px;
  margin-top: 50px;
  width:100%;
}
.border-bottom {
  border-bottom: 1px solid #dddd;
}
.table-desc{
    padding: 1em!important;
}
.table-desc p {
    font-size:1.1em!important;
}
.block-center{
    display:block;
    margin: 0 auto;
}
.line-image {
  padding-top: 1em;
}

.ui.horizontal.new-divider {
  max-width: 600px;
  width: 80%;
  margin: 2em auto;
  padding: 1em;
  display: block;
  position: relative;
  text-align: center;
  text-transform:capitalize;
  white-space: normal;
}
.ui.horizontal.new-divider:before {
  background:url('../images/open-quote.png');
  content: '';
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  left: calc(0% - 3em);
  top: calc(0% - 3em);
  bottom: 0;
  margin: auto;
  display: block;
  background-size: contain;
}
.ui.horizontal.new-divider:after {
  background-image:url('../images/close-quote.png');
  content: '';
  background-repeat: no-repeat;
  width: 100px;
  right: calc(0% - 3em);
  top: 0;
  bottom: calc(0% - 3em);
  margin: auto;
  height: 100px;
  display: block;
  position: absolute;
  background-size: contain;
}


.ui.vertical.stripe h3.text-question {
  font-size: 1.5em;
}

.ui.huge.button.rounded-button {
  background: #ec6563;
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.ui.huge.button.submit-button {
  background: #ec6563;
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 250px;
  border-radius: 50px;
}
button.ui.huge.button.rounded-button:after,button.ui.huge.button.submit-button:after {
    font-family: Icons;
    content: "\f152";
    padding-left:10px;  
}

.ui.huge.button.rounded-button:hover,.ui.huge.button.submit-button:hover {
  background: #52beb2;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
.archetype-table {
    box-shadow: 0 10px 30px rgba(0,0,0,.15);
    padding: 1em !important;
    border-radius: 1em;
}
.m-auto {
    margin:auto;
}
.align-vcenter{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.ui.list>.item {
    padding: .5em 0;
    font-size: 1.1em;
    line-height: 1.5em;
}
.sl-img {
    display: block;
    margin: 1em auto;
    position: relative;
    width:100%;
    max-width:700px;
}
.cover-img {
    max-width: 400px;
    width: 100%;
    display: inline-block;
    float: right;
    padding: 1em;
}
.bolt,.check-circle {
    padding: .5em 0;
    font-size: 1.1em;
    padding-left: 1em;
    line-height: 1.5em;
}
.bolt:before{
    font-family: 'Icons';
    content: "\f0e7";
    color: #52beb2;
    pointer-events: none;
    position: relative;
    margin-left: -1.5em;
    margin-right: 1em;
    opacity: 1;
    vertical-align: middle;
}
.check-circle:before{
    font-family: 'Icons';
    content: "\f058";
    color: #52beb2;
    pointer-events: none;
    position: relative;
    margin-left: -2em;
    margin-right: 1em;
    opacity: 1;
    vertical-align: middle;
}
.logo-overlay{
    height: 50%;
    width: 45%;
    top: 0;
    bottom: 0;
    position: absolute;
    background-image: url('../images/logo/logo-color.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    opacity: .7;
    z-index: 99;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
}
.bm-menu {
    background: #ec6563;
}
.bm-item {
    color: #fff;
}
.bm-item:hover {
    margin-left: 10px;
}
.bm-cross {
    background: #fff;
}
.bm-burger-bars {
    height:5%!important;
}